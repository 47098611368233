// Bootstrap overloads
$breadcrumb-divider: quote("⟩");

// Import all of Bootstrap's CSS
@import "~bootstrap/scss/bootstrap";
$material-symbols-font-path: '../../node_modules/material-symbols/';
@import '~material-symbols/rounded.scss';

body {
  overflow-y: scroll;
}

.is-clickable {
  cursor: pointer;
}

.is-text-link {
  position: relative;
}

.is-text-link:hover::before {
  content: ' ';
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  bottom: -2px;
  border-bottom: solid 2px $primary;
}

.simplex-table .active {
  color: $table-hover-color;
  background-color: $table-hover-bg;
}

.material-icon {
  font-family: 'Material Symbols Rounded';
  user-select: none;
  vertical-align: bottom;
  height: 1em;
  font-variation-settings:
  'FILL' 1,
  'wght' 700,
  'GRAD' 200,
  'opsz' 48;
}
